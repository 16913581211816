<template>
	<div class="pageContainer flexCloumn">
		<el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">
			<div class="flexStart flex_1 w_100" style="flex-wrap:wrap;">


				<el-input v-model="searchForm.plan_name" size="small" placeholder="计划名称"
					style="max-width: 150px; margin-right: 10px;" />
				<el-date-picker v-model="searchForm.sta_end_time" size="small" type="datetimerange" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
					style="width: 350px; margin-right: 10px; margin-left: 10px">
				</el-date-picker>
				<el-button size="small" icon="el-icon-search" @click="onSearch" plain type="primary">查询
				</el-button>
				<el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置
				</el-button>
				<el-button type="primary" size="small" icon="el-icon-plus" @click="onAdd">添加
				</el-button>
			</div>

		</el-row>
		<div class="dividerBar"></div>

		<el-table :data="dataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
			<el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
			<el-table-column prop="plan_name" label="名称" min-width="120"></el-table-column>
			<el-table-column prop="create_name" label="发布人" width="100"></el-table-column>
			<el-table-column prop="send_typeStr" label="调查对象" width="120"></el-table-column>
			<el-table-column prop="cdate" label="问卷发布时间" width="180"></el-table-column>
			<el-table-column prop="end_time" label="调查截止日期" width="180"></el-table-column>
			<el-table-column prop="notice_time" label="通知时间" width="180"></el-table-column>
			<el-table-column prop="finish_ok" label="参与人数" width="100">
				<template slot-scope="scope">
					<span @click="showBaogao(scope.row)">{{ scope.row.finish_ok }}</span>
					
				</template>
			</el-table-column>
			<el-table-column prop="formal" label="发布状态" width="100">
				<template slot-scope="scope">
					<span v-if="scope.row.formal == 0">未发布</span>
					<span v-if="scope.row.formal == 1" style="color: #67c23a;">已发布</span>
				</template>
			</el-table-column>



			<el-table-column prop="" label="二维码" width="100">
				<template slot-scope="scope">
					<div class="qrcode" @click="downImage(scope.row.id)" :id="'qrcode_' + scope.row.id"
						style="width: 40px; height: 40px;"></div>
				</template>

			</el-table-column>

			<el-table-column label="操作" fixed="right" width="220">
				<template slot-scope="scope">
					<div>
						<el-tag
							v-if="scope.row.cuid == teacherInfo.id || teacherInfo.id == 1476"
							class="pointer" type="warning" size="mini" @click="showNotify(scope.row)">通知
						</el-tag>
						<el-popconfirm title="确定删除吗？" @confirm="onDel(scope.row)">
							<el-tag v-if="scope.row.cuid == teacherInfo.id" class="ml10 pointer" slot="reference"
								type="danger" size="mini">删除
							</el-tag>
						</el-popconfirm>
						<el-tag size="mini" v-if="scope.row.cuid == teacherInfo.id" @click="onModify(scope.row)"
							style="margin-left: 10px;cursor: pointer;">
							编辑</el-tag>

						<!-- <el-tag type="warning" size="mini" @click="onView(scope.row)"
							style="margin-left:10px;cursor: pointer;">查看
						</el-tag> -->
						<el-tag v-if="scope.row.send_typeStr.trim() == '学生'" type="success" size="mini" @click="onReport(scope.row)"
							style="margin-left: 10px;cursor: pointer;">报告
						</el-tag>
            <el-tag v-else type="success" size="mini" @click="exportTkReport(scope.row)"
                    style="margin-left: 10px;cursor: pointer;">导出报告
            </el-tag>

					</div>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @current-change="handlePageChange" @size-change="handleSizeChange"
			:current-page="page.current_page" :page-size="page.per_page" :total="page.count"
			layout="total, sizes,prev, pager, next" :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
		</el-pagination>


		<!-- 编辑 -->
		<el-dialog :title="dialodTitle" :visible.sync="showDialog" width="800px" custom-class="cus_dialog">
			<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="90px">
				<el-form-item label="计划名称" prop="plan_name">
					<el-input v-model="ruleForm.plan_name" size="small" placeholder="计划名称"></el-input>
				</el-form-item>
				<el-form-item label="调查时段" prop="sta_end_time">
					<el-date-picker size="small" v-model="ruleForm.sta_end_time" type="datetimerange"
						range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
						:default-time="['00:00:00', '00:00:00']">
					</el-date-picker>
				</el-form-item>
				<!-- <el-form-item label="定时发送">
					<el-date-picker size="small" v-model="ruleForm.notice_time" type="datetime" placeholder="选择定时时间">
					</el-date-picker>
					<span style="color: #409eff;">（若不填此项，则默认立即发送）</span>
				</el-form-item> -->


				<div class="requiredLabel" v-for="(item,index) in ruleForm.moban_arr" :key="index"
					style="margin: 20px 0 0 20px;">
					<div style="color: #409eff;">问卷模板{{ index + 1 }}：
						<el-popconfirm title="确定删除模板吗？" @confirm="delTemplate(index)">
							<el-tag slot="reference" type="danger" size="mini" style="margin-left: 5px;">删除</el-tag>
						</el-popconfirm>
					</div>
					<el-form-item label="调查对象" label-width="80px" style="margin-bottom: 0">
						<el-radio-group v-model="item.type" @change="e=>changeRadio(e,index)">
							<el-radio :label="0">学生</el-radio>

						</el-radio-group>
					</el-form-item>

					<el-form-item label="调查方式" label-width="80px" style="margin-bottom: 0">
						<el-checkbox-group v-if="item.type==0" v-model="item.send_type" @change="$forceUpdate()">

							<el-checkbox :label="2">短信</el-checkbox>
							<el-checkbox :label="1">邮件</el-checkbox>
						</el-checkbox-group>
						<el-checkbox-group v-if="item.type==2" v-model="item.send_type">
							<el-checkbox :label="2">短信</el-checkbox>
							<el-checkbox :label="1">邮件</el-checkbox>
						</el-checkbox-group>
						<el-checkbox-group v-if="item.type==1" v-model="item.send_type">
							<el-checkbox :label="2">短信</el-checkbox>
						</el-checkbox-group>
					</el-form-item>

					<el-form-item v-if="item.type==0" label="问卷模板" label-width="80px" style="margin-bottom: 0">
						<el-select v-model="item.ivst_id" placeholder="请选择" size="small" @change="$forceUpdate()">
							<el-option v-for="items in moban_list00" :key="items.id" :label="items.ivst_title"
								:value="items.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="item.type==2" label="问卷模板" label-width="80px" style="margin-bottom: 0">
						<el-select v-model="item.ivst_id" placeholder="请选择" size="small" @change="$forceUpdate()">
							<el-option v-for="items in moban_list02" :key="items.id" :label="items.ivst_title"
								:value="items.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="item.type==1" label="问卷模板" label-width="80px" style="margin-bottom: 0">
						<el-select v-model="item.ivst_id" placeholder="请选择" size="small" @change="$forceUpdate()">
							<el-option v-for="items in moban_list01" :key="items.id" :label="items.ivst_title"
								:value="items.id">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="发送对象" label-width="80px" style="margin-bottom: 0"
						v-if="item.type == 0">
						<el-button size="small" style="margin-right: 20px;" @click="dialogVisible = true">
							{{ choosedTeachers > 0 ? '教师' + choosedTeachers + '人' : '' }}
							{{ choosedClasses > 0 ? ('班级' + choosedClasses +
							'个' + (choosedStuCount > 0 ? '(学生' + choosedStuCount + '人)' : '') + (choosedParentCount > 0
							?
							'家长'
							+
							choosedParentCount + '位' : ''))
							: '' }}
							{{ choosedClasses == 0 && choosedTeachers == 0 ? '手动选择' : '' }}
						</el-button>
					</el-form-item>

				</div>

				<el-button type="success" size="mini" plain icon="el-icon-plus" @click="addTemplate"
					style="margin: 20px;">
					新增模板
				</el-button>

				<el-form-item label="公开参与" prop="formal">
					<el-radio-group v-model="ruleForm.open">
						<el-radio :label="1">公开</el-radio>
						<el-radio :label="0">不公开</el-radio>
					</el-radio-group>
					<span style="margin-left: 20px; color: orangered; font-size: 12px;">公开的问卷不需要登录和验证身份！</span>
				</el-form-item>


				<el-form-item label="发布状态" prop="formal">
					<el-radio-group v-model="ruleForm.formal">
						<el-radio :label="1">启用</el-radio>
						<el-radio :label="0">存草稿</el-radio>
					</el-radio-group>
				</el-form-item>

			</el-form>

			<div slot="footer" class="dialog-footer flexCenter">
				<el-button @click="showDialog = false">取 消</el-button>
				<el-button type="primary" @click="onSubmit('ruleForm')">提交</el-button>
			</div>
		</el-dialog>



		<!--    完成度-->
		<el-dialog :title="viewTitle" :visible.sync="showDialog_complete" custom-class="cus_dialog_complete width_800">
			<div class="flex_1"
				style="padding:20px 10px;height: 100%;overflow-y: scroll;box-shadow: 0 0 4px 0 #00000051;border-radius: 10px;">
				<div v-for="(item, index) in completeList" :key="index" :id="'dept' + item.index" class=""
					style="width: 100%;height: 300px;"></div>
			</div>
			<div class=""
				style="width:280px;padding:20px 10px 20px 0;margin-left:20px;height: 100%;overflow-y: scroll;box-shadow: 0 0 4px 0 #00000051;border-radius: 10px;">
				<el-tree :data="completeList" :props="defaultProps" :render-content="renderContent"
					:default-expanded-keys="expandedKeys" node-key="name" :expand-on-click-node="false"></el-tree>

			</div>
		</el-dialog>

		<!--    查看-->
		<el-dialog :title="viewTitle" :visible.sync="showDialog_preview" custom-class="cus_dialog_preview">
			<el-row class="previewBox">
				<el-col :span="10" style="height: 100%;">
					<div class="left">
						<el-collapse v-model="ckdViewIvstID">
							<el-collapse-item v-for="(item, index) in tempList" :key="index" :title="item.ivst_title"
								:name="'' + item.ivst_id">
								<div class="tmItem" @click="analysisOne(c, ic + 1)" v-for="(c, ic) in tjTmlist"
									:key="ic" :style="'border-color:' + colorList[ic]">
									<div class="tmTitle">{{ ic + 1 }}. {{ c.tm_title }}</div>
									<div class="tmOptions flexStart">
										<div v-for="(o, io) in c.tm_content" :key="io" style="margin-right: 30px;">
											<!-- {{ (io+1) }}. -->
											{{ o.title }}
										</div>
									</div>
								</div>
							</el-collapse-item>
						</el-collapse>
					</div>
				</el-col>
				<el-col :span="14" style="height: 100%;">
					<div v-show="hasXX" class="right flexCloumnSingleCenter">
						<div class="flexCenter" style="margin-top: 10%;font-size: 18px;font-weight: 700;">
							<!-- {{tmOne.indexC}}.  -->
							{{ tmOne.tm_title }}
						</div>
						<div id="mychart"
							style="margin-top: 10%;width:80%;height:50%;box-shadow: 0 0 10px rgba(0,0,0,0.3);">

						</div>
					</div>
					<div v-show="!hasXX" style="height: 100%; overflow-y: auto;">
						<div style="padding:0 30px;">


							<div class="flexCenter" style="margin-top: 10%;font-size: 18px;font-weight: 700;">
								<!-- {{tmOne.indexC}}. -->
								{{ tmOne.tm_title }}
							</div>
							<div class="words" v-for="(w, i) in wordsList" :key="i">
								{{ w }}
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</el-dialog>


		<!-- 报告 -->
		<el-dialog :title="reportTitle" :visible.sync="showDialog_report" custom-class="cus_dialog cus_dialog_report">
			<div slot="title" class="flexBetween">
				<span style="color: #ffffff;">{{ reportTitle }}</span>
				<div>
          <el-button size="small" icon="el-icon-download" type="primary" plain
                     @click="exportExcel">导出统计表格
          </el-button>
          <el-button size="small" icon="el-icon-download" type="primary" plain
                     @click="exportTkReport(0)">导出表格报告
          </el-button>
          <el-button size="small" icon="el-icon-download" type="primary" plain
                     @click="exportReport('问卷调查报告模板.docx')" style="">导出调研报告
          </el-button>
        </div>
			</div>
			<div class="reportBox">




				<el-tabs v-model="active_report" @tab-click="tabClick">
					<el-tab-pane label="概况" name="first">
						<div class="flexBetween" style="text-align: center; padding: 0 50px ;">
							<div style="width: 300px;">
								<div class="boxitem">
									<div class="boxdesc">
										需完成总人数：{{ gkInfo.stu_total }}
									</div>

								</div>
								<div class="boxitem">
									<div class="boxdesc">
										已完成人数：{{ gkInfo.finish_ok }}
										<br>
										{{ gkInfo.ok_percent || '0' }}%
									</div>

								</div>
								<div class="boxitem">
									<div class="boxdesc">
										未完成人数：{{ gkInfo.finish_not }}
										<br>
										{{ gkInfo.fail_percent || '0' }}%
									</div>

								</div>
							</div>
							<div style="flex-grow: 1; text-align: center;">
								<img src="../../../public/img/plan_001.jpg" alt=""
									style="max-width: 100%;max-height: 100%;">
								<!-- <img :src="require('../../../../public/img/plan_001.jpg')" alt=""
									style="max-width: 100%;max-height: 100%;"> -->
							</div>
							<div v-if="false" style="width: 300px;">
								<div class="boxitem">
									<div class="boxdesc">
										<div style="line-height: 30px;">
											领取红包人数：{{ gkInfo.redpack_count }}人
										</div>

									</div>

								</div>
								<div class="boxitem">
									<div class="boxdesc">
										<div style="line-height: 30px;">
											领取红包总金额：{{ gkInfo.redpack_money }}元
										</div>
									</div>
								</div>
								<div class="boxitem">
									<div class="boxdesc">
										<div style="line-height: initial;">
											红包面额
											<br />
											<div v-for="(item, index) in gkInfo.redpack_data"
												style="font-size: 12px; display: inline-block; margin-right: 20px; margin-bottom: 10px; vertical-align: middle;">
												{{ item.prize_name }}：{{ item.num }}个
											</div>

										</div>

									</div>
								</div>
							</div>
						</div>
					</el-tab-pane>
					<!-- <el-tab-pane :label="tm.tm_title" :name="'idx_'+tm.id" v-for="(tm,idx) in tjTmlist" :key="idx"> -->
					<el-tab-pane label="统计详情" name="tongji">

					</el-tab-pane>
					<el-tab-pane label="题目详情" name="detail">
					</el-tab-pane>
					<el-tab-pane label="选项人数统计" name="class">
					</el-tab-pane>
				</el-tabs>




				<div v-show="active_report == 'detail'" style="height: 100%;">

					<div style="width: 250px; float: left; height: 100%; overflow: auto;">
						<div>
							<div :class="['tm_row', ckdTmrow == tm.id ? 'tm_rowckd' : '']" v-for="(tm, idx) in tjTmlist"
								:key="idx" @click="checkTmRow(tm)">
								【{{ idx + 1}}】{{ tm.tm_title }}
							</div>
						</div>
					</div>
					<div style="margin-left: 260px; height: 100%;">

						<div style="text-align: center; font-weight: bold;">
							{{ ckdTmTitle }}
						</div>

						<div v-show="hasXX">


							<div id="chartitem1"
								style="width:30%; height: 600px; display: inline-block; vertical-align: top;">

							</div>
							<div id="chartitem2"
								style="width:30%; height: 600px; margin-left: 3%; display: inline-block; vertical-align: top;">

							</div>
							<div id="chartitem3"
								style="width:30%; height: 600px; margin-left: 3%; display: inline-block; vertical-align: top;">

							</div>
						</div>
						<div v-show="!hasXX" style="height: 100%; overflow: auto;">
							<div class="words" v-for="(w, i) in wordsList" :key="i">
								{{ w }}
							</div>


						</div>
					</div>
				</div>

				<div v-show="active_report == 'tongji'" style="height: 100%; overflow: auto;">
					<div v-for="y in gyears" :key="y.year" :id="'year' + y.year" style="height: 300px;">
						sadf
					</div>
				</div>

				<div v-show="active_report == 'class'" style="height: 100%; overflow: auto;">
					<clsDetail :plan="plan_id" v-if="active_report == 'class'"></clsDetail>
				</div>

			</div>
		</el-dialog>

		<deptuser v-if="dialogVisible" :notdept="!hasteacher" :oteachers="target_teachers" :oclasses="target_classes">
		</deptuser>

		<smsnotify v-if="showSms" :plan="formData"></smsnotify>
		<reportjoin v-if="showJoinReport" :plan="formData"></reportjoin>
		<reportjoinent v-if="showJoinReportEnt" :plan="formData"></reportjoinent>


	</div>
</template>

<script>
import QRCode from 'qrcodejs2'
import deptuser from '../com/deptuser/deptuser.vue'
import smsnotify from "./smsnotify"
import reportjoin from "./report_join"
import reportjoinent from "./report_join_ent"
import clsDetail from "./clsDetail.vue"
export default {
	components: { deptuser, smsnotify, reportjoin, reportjoinent, clsDetail },
	data() {
		return {
			dialogVisible: false,
			target_teachers: "",
			target_classes: "",
			choosedTeachers: 0,
			choosedClasses: 0,
			choosedStuCount: 0,
			choosedParentCount: 0,
			hasteacher:false,

			searchForm:{},
			page: {
				count: 0,
				current_page: 1,
				per_page: 50,
				total_page: 0
			},

			dataList: [],
			formData: {},
			ruleForm: {
				sort: 1,
				formal: 1,
				open: 0,
				moban_arr: [{
					type: 0,
					send_type: [0, 1, 2],
					checkAll: true,
					isIndeterminate: false,
					selectCoList: [],
				}],
			},
			// isIndeterminate: false,
			// checkAll: true,
			showDialog_co: false,
			coList: [],
			indexCo: 0,
			// selectCoList:[],
			colorList: ['#67c23a', '#f56c6c', '#409eff',],
			rules: {
				plan_name: [{
					required: true,
					message: "请输入计划名称",
					trigger: "blur"
				},],
				sta_end_time: [{
					required: true,
					message: "请选择调查时段",
					trigger: "blur"
				},],
				formal: [{
					required: true,
					message: "请选择发布状态",
					trigger: "blur"
				},],
			},
			showDialog: false,
			showSms: false,
			dialodTitle: '问卷计划新增',
			moban_list00: [], //学生
			moban_list02: [], //企业
			moban_list01: [], //家长
			props: {
				multiple: true,
				emitPath: false,
				value: 'id',
				label: 'stu_name'
			},

			studentsTree: [],
			showDialog_preview: false,
			showDialog_complete: false,
			viewTitle: '',
			showDialog_report2: false,
			reportTitle: '',
			active_report: 'first',
			tempList: [],
			ids: [],
			tmOne: {},

			list: [],
			gkInfo: {},
			tjTmlist: [],
			hasXX: false,
			wordsList: [],
			ckdViewIvstID: [],
			plan_id: 0,
			completeList: [],
			defaultProps: {
				children: 'arr',
				label: 'name'
			},
			rateList: [],
			expandedKeys: [],
			teacherInfo: {},
			showJoinReport: false,
			showJoinReportEnt: false,
			showDialog_report: false,
			ckdTmrow: 0,
			ckdTmTitle: "",
			gyears: [],

		};
	},
	mounted() {
		// console.log(sessionStorage.getItem("auth"));
		this.getInfo()
		this.getlist();
		this.getMobanArrs()
		//this.getStudentsTree()
		//this.getCoList()

	},
	methods: {
		getInfo() {
			this.$http.post("/api/get_teacher_info").then(res => {
				this.teacherInfo = res.data
			})
		},
		expandedTree(data) {
			this.expandedKeys = [];
			for (var i = 0; i < data.length; i++) {
				for (var j = 0; j < data[i].arr.length; j++) {
					this.expandedKeys.push(data[i].arr[j].name);//循环data的数据，把对应要展开的节点name放入展开的数组中
				}
			}
		},
		getlist() {
			this.dataList = []
			this.$http
				.post("/api/bygz_ivst_plan_list", {
					page: this.page.current_page,
					pagesize: this.page.per_page,
					plan_name: this.searchForm.plan_name,
					sta_time: this.searchForm.sta_end_time ? this.searchForm.sta_end_time[0] : '',
					end_time: this.searchForm.sta_end_time ? this.searchForm.sta_end_time[1] : '',
					sta_end_time: this.searchForm.sta_end_time,
				})
				.then((res) => {
					if (res.data && res.data.data && res.data.data.length > 0) {
						let rData = res.data.data
						rData.map(a => {
							let str = ''
							if (a.moban_arr && a.moban_arr.length > 0) {
								a.moban_arr.map(m => {
									str += String(m.type)
								})
								console.log(1111111,str)

								let a0 = '',
									a1 = '',
									a2 = ''
								if (str.indexOf('0') != -1) {
									a0 = '学生'
								}
								if (str.indexOf('1') != -1) {
									a1 = '家长'
								}
								if (str.indexOf('2') != -1) {
									a2 = '企业'
								}
								a.send_typeStr = `${a0} ${a2} ${a1}`
							}

						})
						this.dataList = rData;

						this.page = res.data.page || this.page

						this.$nextTick(() => {
							this.creatQrCode()
						})
					}
				});
		},
		creatQrCode() {
			for (let item of this.dataList) {
				let qrcodetxt = window.location.href.split("#")[0] + "#/p/" + item.id
				var qrcode = new QRCode("qrcode_" + item.id, {
					text: qrcodetxt, // 需要转换为二维码的内容
					width: 500,
					height: 500,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
			}

		},
		handlePageChange(page) {
			this.page.current_page = page
			this.getlist()
		},
		handleSizeChange(val) {
			this.page.current_page = 1;
			this.page.per_page = val;
			this.getlist()
		},
		creatQrCode1() {
			for (let item of this.dataList) {
				if (item.qrcode && item.qrcode.indexOf("qushixi") > 0) {
					let qrcodetxt = item.qrcode
					// console.log(526,qrcodetxt)
					var qrcode = new QRCode("qrcode_" + item.id, {
						text: qrcodetxt, // 需要转换为二维码的内容
						width: 500,
						height: 500,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
				}

			}

		},
		changeMoban() {

			for (let i = 0; i < this.ruleForm.moban_arr.length; i++) {
				let ckdmb = this.ruleForm.moban_arr[i]

				// console.log(ckdmb.ivst_id,mb.id)
				for (let mb of this.moban_list00) {
					if (ckdmb.ivst_id == mb.id) {
						for (let k in mb) {
							ckdmb[k] = mb[k]
						}

						break
					}
				}
			}
			this.$forceUpdate()
		},
		getMobanArrs() {
			this.$http.post("/api/ivst_list", {
				page: 1,
				pagesize: 10000,
			})
				.then((res) => {

					this.moban_list00 = res.data.data
					return
					if (res.data.data && res.data.data.length > 0) {
						let groupArr = this.groupBy(res.data.data, function (item) {
							return [item.target_user]
						})
						let moban_list00 = [],
							moban_list02 = [],
							moban_list01 = []
						if (groupArr && groupArr.length > 0) {
							groupArr.map(a => {
								if (a[0].target_user == 'student') {
									moban_list00 = a
								}
								if (a[0].target_user == 'enterpirse') {
									moban_list02 = a
								}
								if (a[0].target_user == 'parent') {
									moban_list01 = a
								}

								this.moban_list00 = moban_list00
								this.moban_list02 = moban_list02
								this.moban_list01 = moban_list01

							})
						}
					}
				});
		},
		groupBy(array, f) {
			let groups = {};
			array.forEach(function (o) {
				let group = JSON.stringify(f(o));
				groups[group] = groups[group] || [];
				groups[group].push(o);
			});
			return Object.keys(groups).map(function (group) {
				return groups[group];
			});
		},

		getStudentsTree() {
			this.$http.post("/api/by_students_tree").then((res) => {
				if (res.data && res.data.length > 0) {
					this.studentsTree = res.data
				}
			});
		},
		getCoList() {
			let _this = this
			_this.$http.post("/api/by_enterprise_list").then((res) => {
				if (res.data.data && res.data.data.length > 0) {
					_this.coList = res.data.data
					_this.ruleForm.moban_arr[0].selectCoList = res.data.data
				}
			});
		},
		onSearch() {
			this.getlist();
		},
		onReset() {
			this.searchForm = {};
			this.page.current_page = 1;
			this.getlist();
		},
		onAdd() {
			this.ruleForm = {
				sort: 1,
				formal: 1,
				open: 0,
				moban_arr: [{
					type: 0,
					send_type: [0, 1, 2],
					checkAll: true,
					isIndeterminate: false,
					selectCoList: this.coList,
				}],

			}
			this.target_classes = "";
			this.target_teachers = "";
			this.choosedClasses = 0;
			this.choosedTeachers = 0

			this.showDialog = true

		},
		onModify(a) {
			this.$http.post("/api/ivst_plan", { plan_id: a.id, edit: 1 }).then(res => {
				let e = res.data


				e.moban_arr = e.temp
				this.dialodTitle = '问卷计划修改'
				let info = JSON.parse(JSON.stringify(e))
				let sta_end_time = [];
				sta_end_time.push(new Date(e.sta_time));
				sta_end_time.push(new Date(e.end_time));
				info.sta_end_time = sta_end_time

				if (info.moban_arr && info.moban_arr.length > 0) {
					info.moban_arr.map((a, index) => {
						this.target_classes = a.target_classes ? a.target_classes : ""
						this.target_teachers = a.target_teachers ? a.target_teachers : ""
						this.choosedTeachers = 0, this.choosedClasses = 0;
						if (this.target_teachers.indexOf(",") > 0) {
							this.choosedTeachers = this.target_teachers.split(",").length - 2
						} else if (this.target_teachers > 0) {
							this.choosedTeachers = 1
						}
						if (this.target_classes.indexOf(",") > 0) {
							this.choosedClasses = this.target_classes.split(",").length - 2
						} else if (this.target_classes > 0) {
							this.choosedClasses = 1
						}
						if (this.choosedClasses > 0) {
							this.$http.post("/api/get_stu_count_byclsids", { ids: this.target_classes }).then(res => {
								this.choosedStuCount = res.data.n
							})
							//检查是否有家长
							for (let item of info.moban_arr) {
								if (item.target_user.indexOf("parent") >= 0) {
									this.$http.post("/api/get_parent_count_byclsids", { ids: this.target_classes }).then(res => {
										this.choosedParentCount = res.data.n
									})
								}
							}
						}
						if(a.target_user.indexOf("teacher")>=0){
							this.hasteacher = true
						}





					})
				} else {
					info.moban_arr = []
					this.target_classes = ""
					this.target_teachers = ""

					this.choosedTeachers = 0
					this.choosedClasses = 0

				}

				this.ruleForm = {
					...info
				};
				this.showDialog = true;

			})

		},
		onViewComplete(e) {
			// console.log(e)
			this.$http.post("/api/get_ivst_dtqk", {
				plan_id: e.id,
				pmid: 1,
			}).then(res => {
				// console.log(res.data.data)
				this.viewTitle = e.plan_name
				this.showDialog_complete = true
				let ok = 0, no = 0, rateList = [], list = []
				res.data.data && res.data.data.map((a, ia) => {
					a.index = ia
					a.type = 'dept'
					list = []
					a.arr[0].arr && a.arr[0].arr.map(b => {
						ok = 0, no = 0
						b.arr && b.arr.map(ss => {
							// console.log(ss)
							if (ss.id) {
								ok += 1
							} else {
								no += 1
							}
						})
						b.ok = ok
						b.no = no
						b.total = parseInt(ok) + parseInt(no)
						if (b.total == 0) {
							b.rate = 0
						} else {
							b.rate = parseFloat((ok / b.total) * 100).toFixed(2)
						}
						list.push(b)
					})
					rateList.push({
						name: a.name,
						index: a.index,
						list: list
					})
				})
				this.completeList = res.data.data
				this.rateList = rateList
				// console.log(740, rateList)
				this.$nextTick(() => {
					this.expandedTree(res.data.data)
					for (let item of rateList) {
						// console.log('dept' + item.index)
						let myChart = this.$echarts.init(document.getElementById('dept' + item.index))
						myChart.clear();
						let option = {
							color: this.colorList,
							tooltip: {
								trigger: 'axis',
								axisPointer: {
									type: 'cross',
									crossStyle: {
										color: '#999'
									}
								}
							},
							title: [{
								text: item.name
							}],

							legend: {
								data: ['已完成人数', '未完成人数', '完成百分比']
							},
							xAxis: [{
								type: 'category',
								data: item.list.map(e => {
									return e.name
								}),
								axisPointer: {
									type: 'shadow'
								}
							}],
							yAxis: [{
								type: 'value',
								name: '人数',
								min: 0,
								max: 100,
								axisLabel: {
									formatter: '{value} 人'
								}
							},

							{
								type: 'value',
								name: '百分比',
								min: 0,
								max: 100,
								axisLabel: {
									formatter: '{value} %'
								}
							}
							],
							series: [{
								name: '已完成人数',
								type: 'bar',
								data: item.list.map(e => {
									return e.ok
								}),
							},
							{
								name: '未完成人数',
								type: 'bar',
								data: item.list.map(e => {
									return e.no
								}),
							},
							{
								name: '完成百分比',
								type: 'bar',
								data: item.list.map(e => {
									return e.rate
								}),
							}
							]
						};
						myChart.setOption(option)
					}
				})


			})
		},

		renderContent(h, { node, data, store }) {
			// console.log('1h',h,'2node',node,'3data', data,'4store',store)
			return (
				<div class="custom-tree-node" style="width:100%;">
					<div class="flexBetween" style="width:100%;">
						<div className="flexStart" style="width:100%;">
							<span style="min-width:80px;">{data.stu_name ? data.stu_name : node.label}</span>
							<span style="color:#ff8400;">{data.stu_name && !data.id ? '未填写' : ''}</span></div>
						{data.type ? <el-tag onClick={(e) => this.onCopy(e, data)} size="mini">一键复制未完成名单</el-tag> : ''}
					</div>
				</div>
			);
		},
		onCopy(e, data) {
			e.preventDefault()
			// console.log(data)
			let text = ''
			text = `${data.name}未填写人员名单：`
			data.arr[0].arr && data.arr[0].arr.map(a => {
				let flag = 0
				a.arr && a.arr.map(b => {
					if (!b.id) {
						flag = 1
					}
				})
				if (flag) {
					text = `${text}
班级：${a.name}`
					a.arr && a.arr.map(b => {
						if (!b.id) {
							text = `${text}
          ${b.stu_name}`
						}
					})
				}
			})
			// this.handleClipboard(data)


			// console.log(123456,text)


			let textareaEl = document.createElement('textarea')  // 创建一个元素
			textareaEl.value = text   // 将需要复制的内容传给该元素作为文本
			document.body.appendChild(textareaEl)
			textareaEl.select()   // 自动选中
			document.execCommand('copy')  // 这个命令会将选中的内容复制到粘贴板中
			document.body.removeChild(textareaEl)

		},
		onView(e) {

			this.$http.post("/api/ivst_plan", {
				plan_id: e.id,
				edit: 1
			}).then(res => {
				this.tempList = res.data.temp
				this.viewTitle = res.data.plan_name
				this.showDialog_preview = true

				this.changeIvst(this.tempList[0])
			})
		},
		changeIvst(item) {
			this.ckdViewIvstID = [item.ivst_id + ""]

			this.$http.post("/api/bygz_ivst_tjinfo", {
				plan_id: item.plan_id,
				ivst_id: item.ivst_id
			}).then(res => {
				this.tjTmlist = res.data
				if (this.tjTmlist.length > 0) {
					this.analysisOne(this.tjTmlist[0])
				} else {
					this.$message.error("问卷已被删除")
				}

			})
		},

		analysisOne(c, index) {



			let arr_y = [],
				data = []
			this.tmOne = c
			this.tmOne.indexC = index
			if (c.tm_type.indexOf("radio") >= 0 || c.tm_type.indexOf("checkbox") >= 0) {
				this.hasXX = true
				this.$nextTick(() => {
					this.initChart(c)
				})

			} else {
				this.hasXX = false
				this.wordsList = c.answers;
			}
		},
		initChart(c) {
			let _this = this
			let myChart1 = this.$echarts.init(document.getElementById('mychart'))
			let colorList = ['#73c0de', '#91cc75', '#fac858', '#ee6666', '#3ba272',
				'#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'
			]
			let data = c

			myChart1.clear();
			let option = {

				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},

				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'value',
					boundaryGap: [0, 0.01]
				},
				yAxis: {
					type: 'category',
					data: data.tm_content.map(e => {
						return e.title
					})
				},
				series: [{
					name: '选择人数',
					type: 'bar',

					itemStyle: {
						color: function (p) {
							return colorList[p.dataIndex]
						}
					},
					data: data.tm_content.map(e => {
						return e.choose_count
					})
				},

				]
			};
			myChart1.setOption(option)
			window.document.getElementById('mychart').style.height = data.tm_content.length * 50 + 100 + 'px'
			myChart1.resize()
		},
		initChartTM(data) {
			let colorList = ['#73c0de', '#91cc75', '#fac858', '#ee6666', '#3ba272',
				'#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'
			]
			let _this = this
			let myChart1 = this.$echarts.init(document.getElementById('chartitem1'))
			myChart1.clear();
			let option = {
				title: {
					text: '选项统计',
					textAlign: "center",
					x: 'center',
					y: 'top',
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},

				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'value',
					boundaryGap: [0, 0.01]
				},
				yAxis: {
					type: 'category',
					data: data.tm_content.map(e => {
						return e.title
					})
				},
				series: [{
					name: '选择人数',
					type: 'bar',
					barWidth: 30,
					itemStyle: {
						color: function (p) {
							return colorList[p.dataIndex]
						}
					},
					data: data.tm_content.map(e => {
						return e.choose_count
					})
				},

				]
			};
			myChart1.setOption(option)


			let myChart2 = this.$echarts.init(document.getElementById('chartitem2'))
			myChart2.clear();
			let option2 = {
				tooltip: {
					trigger: 'item'
				},
				legend: {
					top: '5%',
					left: 'center'
				},

				color: colorList,
				series: [{
					name: '选择人数',
					type: 'pie',
					radius: ['40%', '70%'],
					avoidLabelOverlap: false,
					itemStyle: {
						borderRadius: 10,
						borderColor: '#fff',
						borderWidth: 2
					},
					label: {
						show: false,
						position: 'center'
					},
					emphasis: {
						label: {
							show: true,
							fontSize: '40',
							fontWeight: 'bold'
						}
					},
					labelLine: {
						show: false
					},
					data: data.tm_content.map(e => {
						return {
							value: e.choose_count,
							name: e.title
						}
					})


				}]
			};
			myChart2.setOption(option2)

			let myChart3 = this.$echarts.init(document.getElementById('chartitem3'))
			myChart3.clear();
			let option3 = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '10%',
					containLabel: true
				},

				xAxis: {
					type: 'category',
					axisLabel: {
						interval: 0,
						rotate: 40
					},
					data: data.tm_content.map(e => {
						return e.title
					})
				},
				yAxis: {
					type: 'value'
				},

				series: [{
					data: data.tm_content.map(e => {
						return e.choose_count
					}),
					type: 'line',
					smooth: true
				}]
			};
			myChart3.setOption(option3)
		},


		onDel(e) {
			this.$http.post("/api/by_ivst_plan_delete", {
				id: e.id,
			}).then(res => {
				this.$message('删除成功！')
				this.page.current_page = 1;
				this.getlist();
			});
		},
		onReport1(e) {
			console.log(e)
			this.formData = e
			if (e.moban_arr && e.moban_arr.length > 0) {
				if (e.moban_arr[0].target_user.indexOf("enterpirse") >= 0) {
					this.showJoinReportEnt = true
				}
			}
			if (!this.showJoinReportEnt) {
				this.showJoinReport = true
			}


		},
		onReport(e) {
			this.plan_id = e.id
			this.$http.post("/api/get_ivst_tj_gk_base", {
				plan_id: e.id,
			}).then(res => {

				let gkInfo = res.data;
				gkInfo.ok_percent = parseInt(gkInfo.finish_ok / gkInfo.stu_total * 10000) / 100
				gkInfo.fail_percent = parseInt(Math.round((100 - gkInfo.ok_percent) * 100)) / 100
				this.gkInfo = gkInfo;
				this.reportTitle = e.plan_name
				this.showDialog_report = true

				this.$http.post("/api/bygz_ivst_tj_tm", {
					plan_id: e.id,
				}).then(res => {

					this.tjTmlist = res.data
					this.active_report = "first"
				})
			});

		},

		tabClick(e) {

			if (e.name == "detail") {
				this.checkTmRow(this.tjTmlist[0])
			} else if (e.name == "tongji") {
				this.getTongji()
			}
		},
		getTongji(e) {

			this.$http.post("/api/nw_gyear_tongji2", {
				plan_id: this.plan_id
			}).then(res => {
				let gyears = []
				let years = {};
				for (let item of res.data) {
					if (!years[item.gyear]) {
						years[item.gyear] = {
							year: item.gyear,
							list: [item]
						}
					} else {
						years[item.gyear].list.push(item)
					}
				}
				for (let k in years) {
					gyears.push(years[k])
				}
				this.gyears = gyears
				this.$nextTick(() => {
					for (let y of gyears) {
						let myChart = this.$echarts.init(document.getElementById('year' + y.year))
						myChart.clear();
						let option = {
							tooltip: {
								trigger: 'axis',
								axisPointer: {
									type: 'cross',
									crossStyle: {
										color: '#999'
									}
								}
							},
							title: [{
								text: y.year
							}],

							legend: {
								data: ['已完成人数', '未完成人数', '完成百分比']
							},
							xAxis: [{
								type: 'category',
								data: y.list.map(e => {
									return e.class_name
								}),
								axisPointer: {
									type: 'shadow'
								}
							}],
							yAxis: [{
								type: 'value',
								name: '人数',
								min: 0,
								max: 100,
								axisLabel: {
									formatter: '{value} 人'
								}
							},

							{
								type: 'value',
								name: '百分比',
								min: 0,
								max: 100,
								axisLabel: {
									formatter: '{value} %'
								}
							}
							],
							series: [{
								name: '已完成人数',
								type: 'bar',
								data: y.list.map(e => {
									return e.joined
								}),
							},
							{
								name: '未完成人数',
								type: 'bar',
								data: y.list.map(e => {
									return e.nojoin
								}),
							},
							{
								name: '完成百分比',
								type: 'bar',
								data: y.list.map(e => {
									return parseInt(e.joined / e.total * 10000) /
										100
								}),
							}
							]
						};
						myChart.setOption(option)
					}
				})





			})
		},
		checkTmRow(e) {

			let tm = e;
			this.ckdTmrow = e.id
			this.ckdTmTitle = e.tm_title

			if (tm) {
				if (tm.tm_type.indexOf("radio") >= 0 || tm.tm_type.indexOf("checkbox") >= 0) {

					this.hasXX = true
					this.$nextTick(() => {
						this.initChartTM(tm)
					})
				} else {
					this.hasXX = false
					this.wordsList = tm.answers
				}


			}
		},

		showNotify(scope) {
			this.showSms = true;
			this.formData = scope
		},
		
		tabClick1(e) {

			if (this.active_report != "first") {
				let tmid = this.active_report.replace("idx_", "")
				let tm = null;
				for (let item of this.tjTmlist) {
					if (item.id == tmid) {
						tm = item;
						break
					}
				}

				if (tm) {
					if (tm.tm_type.indexOf("radio") >= 0 || tm.tm_type.indexOf("checkbox") >= 0) {

						this.hasXX = true
						this.$nextTick(() => {
							this.initChartTM(tm)
						})
					} else {
						this.hasXX = false
						this.wordsList = tm.answers
					}


				}
			} else {

			}

		},

		addTemplate(index) {
			let obj = {
				type: 0,
				send_type: [0, 1, 2],
				isIndeterminate: false,
				checkAll: true,
				selectCoList: this.coList,
			}
			this.ruleForm.moban_arr.push(obj)

		},
		delTemplate(index) {
			this.ruleForm.moban_arr.splice(index, 1)
		},
		changeRadio(e, index) {

			let _this = this
			this.indexCo = index
			this.ruleForm.moban_arr[index].ivst_id = ""
			this.ruleForm.moban_arr[index].target_user = []
			this.ruleForm.moban_arr[index].selectCoList = this.coList
			if (e == 0) {
				this.ruleForm.moban_arr[index].send_type = [0, 2, 1]
			}
			if (e == 2) {
				this.ruleForm.moban_arr[index].send_type = [2, 1]
			}
			if (e == 1) {
				this.ruleForm.moban_arr[index].send_type = [2]
			}
			this.ruleForm = {
				..._this.ruleForm
			}
		},
		changeCascader(e, index) {
			// console.log(e, index)
			this.indexCo = index
			this.ruleForm.moban_arr[index].target_user = e

		},
		chooseAllCascader(index) {
			let _this = this
			this.indexCo = index
			let arr = this.setVal()
			this.ruleForm.moban_arr[index].target_user = []
			this.ruleForm.moban_arr[index].target_user = arr
			// console.log(arr)
			this.ruleForm = {
				..._this.ruleForm
			}
		},
		//学生树全选遍历赋值
		setVal() {
			let arr = []
			this.studentsTree.map(a => {
				a.children && a.children.map(b => {
					b.children && b.children.map(c => {
						c.children && c.children.map(d => {
							arr.push(d.id)
						})
					})
				})
			})
			// console.log(arr)
			return arr
		},
		checkAllCo(index) {
			this.indexCo = index
			this.ruleForm.moban_arr[index].checkAll = !this.ruleForm.moban_arr[index].checkAll
			this.ruleForm.moban_arr[index].isIndeterminate = false
			this.ruleForm.moban_arr[index].selectCoList = this.coList
		},
		openCoList(index) {
			let _this = this
			_this.indexCo = index
			this.showDialog_co = true
			_this.$nextTick(function () {
				_this.$refs.coMuliTable.clearSelection()
				let arr = _this.ruleForm.moban_arr[index].selectCoList
				if (arr && arr.length > 0) {
					arr.forEach((item, index) => {
						_this.$refs.coMuliTable.toggleRowSelection(item, true);
					});
				}
			})
		},
		muliSelectTableCo(e) {
			// console.log(e)
			let index = this.indexCo
			this.ruleForm.moban_arr[index].selectCoList = e
		},
		muliSelectTableCo_all(e) {
			// console.log(e)
			let index = this.indexCo
			this.ruleForm.moban_arr[index].selectCoList = e
		},
		closeCoList() {
			let _this = this
			let index = this.indexCo
			let checkedCount = this.ruleForm.moban_arr[index].selectCoList.length;
			this.ruleForm.moban_arr[index].checkAll = checkedCount === this.coList.length;
			this.ruleForm.moban_arr[index].isIndeterminate = checkedCount > 0 && checkedCount < this.coList.length;

			_this.showDialog_co = false
		},
		onSubmit(formName) {
			let _this = this

			let params = JSON.parse(JSON.stringify(_this.ruleForm));


			if (!params.plan_name) {
				this.$message.warning(`请填写问卷名称！`)
				return
			}
			if (!params.sta_end_time) {
				this.$message.warning(`选择调查时段！`)
				return
			}
			let isenterprise = false
			for (let mb of this.ruleForm.moban_arr) {
				for (let cmb of params.moban_arr) {
					if (mb.id == cmb.ivst_id) {
						if (mb.target_user == "enterprise") {
							isenterprise = true

						}
					}
				}

			}

			if (!params.moban_arr || params.moban_arr.length == 0) {
				this.$message.warning('请添加一条模板！')
				return
			}

			if (!isenterprise) {
				if (!this.target_classes || !this.target_teachers) {
					this.$message.warning('请选择发送对象！')
					return
				}
			}

			if (params.formal == null || params.formal == undefined) {
				this.$message.warning(`选择发布状态！`)
				return
			}
			params.target_classes = this.target_classes
			params.target_teachers = this.target_teachers

			//检查是否有重复模板
			let ivstDic = {};
			for (let ivst of params.moban_arr) {
				if (ivstDic[ivst.ivst_id]) {
					this.$message.error("不能选择重复模板")
					return
				} else {
					ivstDic[ivst.ivst_id] = 1
				}
			}


			this.$http.post("/api/by_ivst_plan_edit", params).then((res) => {
				if (res.data.code == 200) {
					this.$message.success('提交成功！')
					this.showDialog = false;
					this.getlist();
				} else {
					this.$message.error(res.data.msg)
				}
			});


		},
		exportReport(filename) {
			this.$message.warning("正在生成文件，请耐心等待！")
			let url = window.location.href.split("plan")[0]
			url = url + "report?auth=" + sessionStorage.getItem("auth") + "&plan_id=" + this.plan_id
			this.$http.post(`${this.$pdfUrl}/surveyReport`, {
				pmid: window.pmid,
        stuArr: [{id:this.plan_id,name:this.reportTitle}]
			}).then((res) => {
				window.open(res.data.url, "_blank")
			});
		},
    exportTkReport(row) {
      let planid = row ? row.id : this.plan_id
      let url = "/api/surveyReportXls"
      if (row && row.send_typeStr.trim() == '企业') {
        url = "/api/surveyEnterReportXls"
      }
      this.$http.post(url, {
        plan_id:  planid
      }).then((res) => {
        window.open(res.data.url, '_blank')
      });
    },
		createMiniQrcode(row) {

			this.$http.post("/api/plan_qrcode_mini", {
				id: row.id
			}).then((res) => {
				this.getlist()
				this.$message.success("生成成功")
			});

		},
		createH5Qrcode(row) {
			let chs = 1;//企业端不用选人

			if (row.moban_arr && row.moban_arr[0].target_user == "enterpirse") {
				chs = 0
			}
			let url = "https://sx.qushixi.org.cn/#/h5_ivst_plan?pid=" + row.id + "&pmid=" + window.pmid
			if (chs) {
				url += "&chs=1"
			}
			this.$http.post("/api/plan_qrcode_h5", {
				id: row.id,
				qrcode_url: url

			}).then((res) => {
				this.getlist()
				this.$message.success("生成成功")
			});
		},
		downImage(e) {

			let that = this;

			function downloadFile(fileName, content) {
				let aLink = document.createElement('a');
				let blob = base64ToBlob(content); //new Blob([content]);

				let evt = document.createEvent("HTMLEvents");
				evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
				aLink.download = fileName;
				aLink.href = URL.createObjectURL(blob);

				// aLink.dispatchEvent(evt);
				aLink.click()
			}
			//base64转blob
			function base64ToBlob(code) {
				let parts = code.split(';base64,');
				let contentType = parts[0].split(':')[1];
				let raw = window.atob(parts[1]);
				let rawLength = raw.length;

				let uInt8Array = new Uint8Array(rawLength);

				for (let i = 0; i < rawLength; ++i) {
					uInt8Array[i] = raw.charCodeAt(i);
				}
				return new Blob([uInt8Array], {
					type: contentType
				});
			}

			function download() {
				let title = "";
				for (let item of that.dataList) {
					if (item.id == e) {
						title = item.plan_name
					}
				}
				let imgData = $("#qrcode_" + e).find("img").attr("src");
				downloadFile(title + ".png", imgData);
			}
			download()


		},
		setTarget(e) {
			if (e.teachers && e.teachers.length > 0) {
				this.choosedTeachers = e.teachers.length
				this.target_teachers = "0," + e.teachers.join(',') + ",0"
			} else {
				this.target_teachers = "0"
				this.choosedTeachers = 0
			}

			if (e.classes && e.classes.length > 0) {
				this.target_classes = "0," + e.classes.join(',') + ",0"
				this.choosedClasses = e.classes.length
				this.$http.post("/api/get_stu_count_byclsids", { ids: this.target_classes }).then(res => {
					this.choosedStuCount = res.data.n
				})
				//检查是否有家长
				for (let item of this.formData.moban_arr) {
					if (item.target_user.indexOf("parent") >= 0) {
						this.$http.post("/api/get_parent_count_byclsids", { ids: this.target_classes }).then(res => {
							this.choosedParentCount = res.data.n
						})
					}
				}
			} else {
				this.target_classes = "0"
				this.choosedClasses = 0
			}


		},
		
		createQrcode(e) {


			this.$http.post("/api/create_plan_qrcode", { id: e.id }).then(res => {
				this.getlist();
			})

		},
		createQrcodeNew(e) {
			let qrcodetxt = window.location.href.split("#")[0] + "#/p/" + e.pmid + "$" + e.id + "$s$0$m" + '?pmid=' + e.pmid
			// console.log(526,qrcodetxt)
			var qrcode = new QRCode("qrcode_" + e.id, {
				text: qrcodetxt, // 需要转换为二维码的内容
				width: 500,
				height: 500,
				colorDark: '#000000',
				colorLight: '#ffffff',
				correctLevel: QRCode.CorrectLevel.H
			})

		},
		showBaogao(row){
			window.open("/#/reportMajor?plan_id="+row.id,"_blank")
		},
    exportExcel() {
      this.$http.post('/api/njjs_survey_statistics', {
        plan_id: this.plan_id
      }).then(res => {
        window.open(res.data.url, '_blank')
      })
    }
	},
};
</script>
<style type="text/less" lang="less">
.requiredLabel {

	.el-form-item__label {
		position: relative;
	}

	.el-form-item__label::before {
		content: '*';
		color: #ff5500;
		position: absolute;
		left: -2px;
	}
}

.cus_dialog_complete {
	width: 90%;
	height: 80vh;
	margin-top: 5vh !important;

	div {
		box-sizing: border-box
	}

	.el-dialog__body {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		height: calc(100% - 44px);

		.reportBox {
			height: 100%;

			.el-tabs {
				/*height: 100%;*/

				.el-tabs__content {
					/*height: calc(100% - 55px);*/
				}
			}

			.thumbnail {
				width: 100%;
				height: 100%;

				img {
					display: inline-block;
					max-width: 100%;
					max-height: 100%;
				}
			}
		}

		.el-tree-node__content {
			width: 100%;
			//background-color: pink;
		}
	}
}

.cus_dialog_preview {
	//width: 375px;
	//margin-top: 10vh;
	width: 1200px;
	height: 78vh;

	.el-dialog__body {
		height: calc(100% - 120px);

		/*滚动条背景*/
		::-webkit-scrollbar-track {
			background-color: #f8f8f8;
			border-radius: 6px;
		}

		.previewBox {
			height: 100%;

			.left {
				height: 100%;
				overflow-y: scroll;

				.tmItem {
					background-color: #f8f8f8;
					margin-bottom: 15px;
					padding: 10px;
					box-sizing: border-box;
					border-left: 5px solid #409eff;

					.tmTitle {
						font-size: 16px;
					}

					.tmOptions {
						font-size: 14px;
						flex-wrap: wrap;
					}
				}
			}

			.right {
				height: 100%;
				overflow-y: scroll;

			}
		}
	}
}


.cus_dialog_report {
	width: 90%;
	height: 90vh;
	margin-top: 5vh !important;

	.el-dialog__body {
		height: calc(100% - 120px);

		.reportBox {
			height: 100%;

			.el-tabs {

				.el-tabs__content {}
			}

			.thumbnail {
				width: 100%;
				height: 100%;

				img {
					display: inline-block;
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}

	.boxitem {
		width: 220px;
		min-height: 90px;
		display: inline-block;
		background-image: url(../../../public/img/bx_bg.png);
		background-size: 100% 100%;
		margin-bottom: 20px;
		color: #4dadea;
		font-size: 16px;

		.boxdesc {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			min-height: 90px;
		}
	}

}

.words {
	padding: 5px 10px;
	margin: 0 10px;
	text-align: center;
	border-bottom: 1px solid #ccc;
	display: inline-block;
}

.qrcode img {
	width: 100%;
	height: 100%;
}

.tm_row {
	padding: 5px;
	border-bottom: 1px solid #eee;
	cursor: pointer;
}

.tm_row:hover {
	background-color: #eee;
}

.tm_rowckd {
	color: #000FFF;
	font-weight: bolder;
}
</style>
